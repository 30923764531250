<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>組織資料中心</b-breadcrumb-item>
            <b-breadcrumb-item active>關鍵字管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">
          {{ `【${organization.name}】關鍵字管理` }}
        </h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="getKeywords"
              ><i class="fa fa-search"></i
            ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.KEYWORD_LIST_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'KeywordCreate' }"
            ><i class="fa fa-plus"></i>新增關鍵字</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="keywords"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'KeywordView',
                      params: { keyword_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-eye"></span> 查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.KEYWORD_LIST_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'KeywordEdit',
                      params: { keyword_id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.KEYWORD_LIST_MODIFY])"
                    variant="inverse-danger"
                    @click="deleteKeyword(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="getKeywords"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import keywordApi from "@/apis/keyword";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { format } from "date-fns";

export default {
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      keywords: [],
      keyword: null,
      fields: [
        {
          key: "keyword",
          label: "關鍵字",
        },
        {
          key: "enabled",
          label: "啟用",
          formatter: (value) => {
            return value ? "是" : "否";
          },
        },
        {
          key: "is_once",
          label: "一次性回覆",
          formatter: (value) => {
            return value ? "是" : "否";
          },
        },
        {
          key: "is_regex",
          label: "正規表示",
          formatter: (value) => {
            return value ? "是" : "否";
          },
        },
        {
          key: "created_at",
          label: "建立時間",
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  watch: {
    currentPage() {
      this.getKeywords();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getKeywords();
    },
    async getKeywords() {
      this.showLoading = true;
      try {
        let params = {
          per_page: this.perPage,
          page: this.currentPage,
          keyword: this.keyword,
        };

        const { data } = await keywordApi.getKeywords(
          this.organization,
          params
        );
        this.keywords = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得關鍵字資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteKeyword(keyword) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>關鍵字：${keyword.keyword}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await keywordApi.removeKeyword(
              this.organization,
              keyword
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + keyword.keyword,
              });
              this.getKeywords();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
